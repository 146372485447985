import React from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'
import { parse } from 'marked'

import 'twin.macro'
import { h2Styles, h4Styles, paragraphStyles } from '../styles'

const markdownStyles = css`
  p {
    ${tw`mb-5`};
  }
`

const PortfolioItemTemplate = ({
  title,
  brief,
  approach,
  bignumbers,
  blackcoverimage,
  coverimage
}) => {
  return (
    <article tw="w-full px-6 py-10">
      {title && (
        <header tw="mb-10">
          <strong css={h2Styles}>{title}</strong>
        </header>
      )}
      <div tw="flex gap-x-10 pr-10 mb-10">
        {blackcoverimage && <img tw="w-3/6" src={blackcoverimage} alt="black cover" />}
        {coverimage && <img tw="w-3/6" src={coverimage} alt="color cover" />}
      </div>
      {brief && (
        <div tw="mb-10">
          <strong css={h4Styles} tw="flex font-subheading mb-5">
            Brief
          </strong>
          <p css={paragraphStyles}>{brief}</p>
        </div>
      )}
      {approach && (
        <div tw="mb-10">
          <strong css={h4Styles} tw="flex font-subheading mb-5">
            Approach
          </strong>
          <div
            dangerouslySetInnerHTML={{ __html: parse(approach) }}
            css={[paragraphStyles, markdownStyles]}
          />
        </div>
      )}
      {bignumbers && (
        <div>
          <strong css={h4Styles} tw="flex font-subheading mb-5">
            Big Numbers
          </strong>
          <div
            dangerouslySetInnerHTML={{ __html: parse(bignumbers) }}
            css={[paragraphStyles, markdownStyles]}
          />
        </div>
      )}
    </article>
  )
}

PortfolioItemTemplate.propTypes = {
  title: PropTypes.string,
  brief: PropTypes.string,
  approach: PropTypes.string,
  bignumbers: PropTypes.string,
  blackcoverimage: PropTypes.string,
  coverimage: PropTypes.string
}

export default PortfolioItemTemplate
