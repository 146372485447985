import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import 'twin.macro'

import Layout from '../components/Layout'
import PortfolioItemTemplate from './PortfolioItemTemplate'

const PortfolioItem = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title, brief, approach, bignumbers }
    }
  } = data

  const templateData = { title, brief, approach, bignumbers }

  return (
    <Layout title={title} description={brief}>
      <PortfolioItemTemplate {...templateData} />
    </Layout>
  )
}

PortfolioItem.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default PortfolioItem

export const pageQuery = graphql`
  query PortfolioItemByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        brief
        approach
        bignumbers
      }
    }
  }
`
